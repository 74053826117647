<template>
  <div>
    <div style="position: sticky; top: -32px; z-index: 1;">
      <section v-if="faciData" class="hero is-hero-bar">
        <div class="hero-body is-hidden-desktop pb-3">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="title">
                  <div class="is-size-7">
                    Facilitator Dashboard
                    <!-- <span>
                      ({{ refreshSeconds ? `Auto-refreshing in ${refreshSeconds}` : 'Refreshing...' }})
                    </span> -->
                  </div>
                  <div class="is-size-5">
                    {{ session ? `${session.name}` : '' }}
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-body is-hidden-touch">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="title">
                  <div class="is-size-7">
                    Facilitator Dashboard
                    <!-- <span class="is-hidden-desktop">
                      ({{ refreshSeconds ? `Auto-refreshing in ${refreshSeconds}` : 'Refreshing...' }})
                    </span> -->
                  </div>
                  <div class="is-size-3">
                    {{ session ? `${session.name}` : '' }}
                  </div>
                </h1>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <!-- <div class="has-text-dark">
                  {{ refreshSeconds ? `Auto-refreshing in ${refreshSeconds}` : 'Refreshing...' }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="faciData" class="has-background-white p-0">
        <div class="buttons is-justify-content-center pt-3 pb-2 px-2">
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded" :class="{'is-primary': [null, 'null'].includes(shownFaciTab), 'is-primary is-light': ![null, 'null'].includes(shownFaciTab)}" @click="$store.commit('shownFaciTab', null)">
            <i class="fas fa-trophy mr-2"></i>
            Leaderboard
          </div>
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded"
          :class="{
            'is-primary': shownFaciTab === 'Messages' && faciData.allowFaciMessaging,
            'is-primary is-light': shownFaciTab !== 'Messages' && faciData.allowFaciMessaging,
            'is-grey': faciData.allowFaciMessaging
          }"
          @click="$store.commit('shownFaciTab', 'Messages')">
            <i v-if="!faciData.allowFaciMessaging" class="fas fa-exclamation-circle mr-2"></i>
            <i class="fas fa-headset mr-2"></i>
            Broadcast & Messages
            <span v-if="pendingMessages" class="tag is-rounded is-danger ml-2 ">{{ pendingMessages }}</span>
          </div>
          <div class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'Judgements', 'is-primary is-light': shownFaciTab !== 'Judgements'}" @click="$store.commit('shownFaciTab', 'Judgements')">
            <i class="fas fa-gavel mr-2"></i>
            Judgements
            <span v-if="pendingJudgements" class="tag is-rounded is-danger ml-2 ">{{ pendingJudgements }}</span>
          </div>
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'Points', 'is-primary is-light': shownFaciTab !== 'Points'}" @click="$store.commit('shownFaciTab', 'Points')">
            <i class="fas fa-star mr-2"></i>
            Change Team's Points
          </div>
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'Chapters', 'is-primary is-light': shownFaciTab !== 'Chapters'}" @click="$store.commit('shownFaciTab', 'Chapters')">
            <i class="fas fa-forward mr-2"></i>
            Change Team's Chapter
          </div>
          <!-- looks like not needed - Mo - 24 Nov 2024 -->
          <!-- <div class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'StartedAt', 'is-primary is-light': shownFaciTab !== 'StartedAt'}" @click="$store.commit('shownFaciTab', 'StartedAt')">
            <i class="fas fa-clock mr-2"></i>
            Change Team's Start Time
          </div> -->
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'Categories', 'is-primary is-light': shownFaciTab !== 'Categories'}" @click="$store.commit('shownFaciTab', 'Categories')">
            <i class="fas fa-tasks mr-2"></i>
            Toggle Task Categories
          </div>
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'Gallery', 'is-primary is-light': shownFaciTab !== 'Gallery'}" @click="$store.commit('shownFaciTab', 'Gallery')">
            <i class="fas fa-photo-video mr-2"></i>
            Gallery
          </div>
          <div v-if="!hasJjjjInFaciKey" class="button is-small is-rounded" :class="{'is-primary': shownFaciTab === 'Map', 'is-primary is-light': shownFaciTab !== 'Map'}" @click="$store.commit('shownFaciTab', 'Map')">
            <i class="fas fa-map-marked-alt mr-2"></i>
            Map
          </div>
        </div>
      </section>
    </div>
    <div v-if="!faciData" class="container has-text-centered py-6 mt-6">
      <i class="spinning fas fa-4x fa-circle-notch pointer has-text-grey-light"></i>
      <div class="mt-2 has-text-grey-light">
        Games with large number of players may take longer to load.
      </div>
    </div>
    <div v-if="faciData" class="faci-columns">
      <div v-if="[null, 'null'].includes(shownFaciTab)" class="faci-column">
        <card-component
          v-if="ranking"
          class="has-table has-mobile-sort-spaced"
          title="Teams and Progress"
          icon="fa-trophy"
        >
          <adventure-teams-progress-table :adventureTeams="adventureTeams" :ranking="ranking" class="tile is-child" />
        </card-component>

        <card-component
          v-if="adventureTeams.some(x => x.clan)"
          class="has-table has-mobile-sort-spaced"
          title="Clans"
          icon="fa-trophy"
        >
          <clans-table :adventureTeams="adventureTeams" :ranking="ranking" class="tile is-child" />
        </card-component>
      </div>
      <div v-if="shownFaciTab == 'Judgements'" class="faci-column">
        <div class="level is-mobile mb-2">
          <div class="level-left">
            <!-- <div class="level-item has-text-weight-semibold mr-0 mb-0">
              Use old UI
            </div>
            <div class="level-item">
              <b-field label="" horizontal>
                <b-switch v-model="useOldJudgementUi">
                </b-switch>
              </b-field>
            </div> -->
            <div class="level-item has-text-weight-semibold mr-0 mb-0">
              <i class="fas fa-bell mr-1"></i>
              Sound
              <b-switch class="ml-2" v-model="playDingOnJudgementChange">
              </b-switch>
            </div>
            <div class="level-item has-text-weight-semibold mr-0 mb-0">
              Show all
              <b-switch class="ml-2" v-model="showJudgedTasks">
              </b-switch>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <button class="button is-rounded is-outlined is-small is-primary mb-1" @click="openTelegramBot">
                <i class="fas fa-bell mr-2"></i>
                Get notified on Telegram
              </button>
            </div>
          </div>
        </div>
        <card-component
          class="has-table has-mobile-sort-spaced"
          title="Judgements"
          icon="fa-gavel"
        >
          <judgements-table
            v-if="!useOldJudgementUi"
            :tableData="filteredAdventureJudgements"
            :checkable="false"
            :faciKey="cleanFaciKey"
          />
          <judgements-table-old
            v-if="useOldJudgementUi"
            :tableData="filteredAdventureJudgements"
            :checkable="false"
            :faciKey="cleanFaciKey"
            :sessionId="session.id"
            v-on:refresh="getFaciData"
          />
        </card-component>
      </div>
      <div v-if="shownFaciTab == 'Messages'" class="faci-column">
        <article v-if="!faciData.allowFaciMessaging" class="message is-warning">
          <div class="message-header">
            <p>Warning</p>
          </div>
          <div class="message-body">
            Team-fo-faci messaging is disabled, players are currently not able to send messages to the facilitator here. Please enable team-to-faci messaging in the Manage Session screen in the PlayTours admin system.
          </div>
        </article>
        <div class="level is-mobile mb-2">
          <div class="level-left">
            <div class="level-item">
              <div class="level-item has-text-weight-semibold mr-0 mb-0">
                <i class="fas fa-bell mr-2"></i>
                Sound
                <b-switch class="ml-2" v-model="playDingOnMessageChange">
                </b-switch>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <button class="button is-rounded is-outlined is-small is-primary mb-1" @click="openTelegramBot">
                <i class="fas fa-bell mr-2"></i>
                Get notified on Telegram
              </button>
            </div>
          </div>
        </div>
        <card-component
          class="has-table has-mobile-sort-spaced"
          title="Team-to-facilitator Messages"
          icon="fa-headset"
        >
          <faci-messages-table
            :tableData="faciMessages"
            :checkable="false"
            :faciKey="cleanFaciKey"
            v-on:refresh="getFaciData"
          />
        </card-component>
      </div>
      <div v-if="['Messages'].includes(shownFaciTab)" class="faci-column">
        <broadcast-message-form :adventureTeams="adventureTeams" :faciKey="cleanFaciKey" class="tile is-child" />
      </div>
      <!-- <div v-if="!shownFaciTab" class="faci-column">
        <card-component
          v-if="ranking"
          class="has-table has-mobile-sort-spaced"
          title="Session Leaderboard"
          icon="fa-stream"
        >
          <leaderboard :ranking="ranking" class="tile is-child" />
        </card-component>
      </div> -->
      <div v-if="shownFaciTab == 'Map'" class="faci-column">
        <card-component
          v-if="ranking"
          class="has-table has-mobile-sort-spaced"
          title="Teams' Positions (if available)"
          icon="fa-map-marked-alt"
        >
          <teams-positions-map
          v-if="adventureTeams"
          :adventureTeams="adventureTeams"
          class="tile is-child"
          :key="refresher"
          :mapSettings="mapSettings"
          v-on:updateMapSettings="updateMapSettings($event)"
          />
        </card-component>
      </div>
      <div v-if="shownFaciTab == 'Categories' && faciData.taskCategories && faciData.taskCategories.length > 0" class="faci-column">
        <toggle-task-categories :faciKey="cleanFaciKey" class="tile is-child" />
      </div>
      <div v-if="shownFaciTab == 'Points'" class="faci-column">
        <change-team-points :adventureTeams="adventureTeams" :faciKey="cleanFaciKey" class="tile is-child" />
      </div>
      <div v-if="shownFaciTab == 'Chapters'" class="faci-column">
        <change-team-stage :adventureTeams="adventureTeams" :faciKey="cleanFaciKey" class="tile is-child" />
      </div>
      <div v-if="shownFaciTab == 'StartedAt'" class="faci-column">
        <change-team-started-at :adventureTeams="adventureTeams" :faciKey="cleanFaciKey" class="tile is-child" />
      </div>
      <div v-if="shownFaciTab == 'Gallery'" class="faci-column">
        <card-component
          v-if="galleryPhotos && galleryPhotos.length > 0"
          class="has-table has-mobile-sort-spaced"
          title="Gallery"
          icon="fa-photo-video"
        >
          <session-photo-gallery :galleryPhotos="galleryPhotos" class="tile is-child" />
        </card-component>
        <div v-else class="container has-text-centered has-text-grey-light mt-6 pt-6">
          <i class="fas fa-photo-video mr-2"></i>
          Nothing in gallery now
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import throttle from '@/utils/throttle'
import JudgementsTable from '@/components/JudgementsTable'
import JudgementsTableOld from '../components/JudgementsTableOld.vue'
import FaciMessagesTable from '@/components/FaciMessagesTable'
import ChangeTeamPoints from '@/components/ChangeTeamPoints'
import ChangeTeamStage from '@/components/ChangeTeamStage'
import ChangeTeamStartedAt from '@/components/ChangeTeamStartedAt'
import BroadcastMessageForm from '@/components/BroadcastMessageForm'
import ToggleTaskCategories from '@/components/ToggleTaskCategories'
import AdventureTeamsProgressTable from '@/components/AdventureTeamsProgressTable'
import ClansTable from '@/components/ClansTable'
import TeamsPositionsMap from '@/components/TeamsPositionsMap'
import SessionPhotoGallery from './SessionPhotoGallery.vue'
import CardComponent from '@/components/CardComponent'
// import TitleBar from '@/components/TitleBar'

export default {
  name: 'Facilitator',
  components: {
    JudgementsTable,
    CardComponent,
    BroadcastMessageForm,
    AdventureTeamsProgressTable,
    ClansTable,
    TeamsPositionsMap,
    FaciMessagesTable,
    ToggleTaskCategories,
    SessionPhotoGallery,
    ChangeTeamPoints,
    ChangeTeamStage,
    ChangeTeamStartedAt,
    JudgementsTableOld
  },
  data () {
    return {
      faciSessionUpdater: null,
      faciKey: this.$route.params.faciKey,
      showJudgedTasks: false,
      session: null,
      adventureJudgements: [],
      adventureTeams: [],
      ranking: [],
      faciMessages: [],
      galleryPhotos: [],
      refreshCount: 0,
      // nextRefreshSeconds: 10,
      // nextRefreshTime: null,
      // refreshSeconds: null,
      playDingOnJudgementChange: true,
      playDingOnMessageChange: true,
      errorMessage: null,
      ding: null,
      mapSettings: null,
      refresher: 0,
      useOldJudgementUi: true,
      getFaciDataThrottled: throttle(this.getFaciData, 20000)
    }
  },
  methods: {
    openTelegramBot () {
      window.open(`https://t.me/playtours_bot?start=SESSIONID${this.session.id}`)
    },
    playDing () {
      if (!this.ding) {
        this.ding = new Audio('https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2Ffaci-ding.mp3?alt=media&token=22bdf122-3390-476c-9298-02aa656da035')
      }
      this.ding.play()
    },
    getFaciData () {
      if (!this.errorMessage) {
        this.$store.dispatch('getFaciData', this.cleanFaciKey)
        this.refresher += 1
        this.refreshCount += 1
      }
    },
    getRefreshSecondsText () {
      const startTime = new Date()
      let msec = this.nextRefreshTime - startTime
      if (msec > 0) {
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000
        this.refreshSeconds = `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
      } else {
        // this.getFaciData()
        this.refreshSeconds = null
        const dt = new Date()
        dt.setSeconds(dt.getSeconds() + this.nextRefreshSeconds)
        this.nextRefreshTime = dt
      }
    },
    updateMapSettings (mapSettings) {
      this.mapSettings = {
        lat: mapSettings.lat,
        lon: mapSettings.lon,
        zoom: mapSettings.zoom
      }
    }
  },
  computed: {
    faciData () {
      return this.$store.state.faciData
    },
    shownFaciTab () {
      return this.$store.state.shownFaciTab
    },
    shownActionTab () {
      return this.$store.state.shownActionTab
    },
    filteredAdventureJudgements () {
      if (this.showJudgedTasks) {
        return this.adventureJudgements
      } else {
        return this.adventureJudgements.filter(el => {
          return el.isPendingJudgement
        })
      }
    },
    pendingJudgements () {
      if (this.adventureJudgements) {
        return this.adventureJudgements.reduce((total, judgement) => {
          return total + (judgement.isPendingJudgement ? 1 : 0)
        }, 0)
      }
      return 0
    },
    pendingMessages () {
      if (this.faciMessages) {
        const faciMessages = [...this.faciMessages].sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1
          }
          if (a.createdAt > b.createdAt) {
            return -1
          }
          return 0
        })
        return faciMessages.reduce((total, message, index) => {
          if (!message.repliedByFaci) {
            if (
              index + 1 < faciMessages.length &&
              !faciMessages[index + 1].repliedByFaci &&
              message.name === faciMessages[index + 1].name &&
              message.teamName === faciMessages[index + 1].teamName &&
              Math.abs(new Date(message.createdAt) - new Date(faciMessages[index + 1].createdAt)) < (1.5 * 60 * 1000)
            ) {
              return total
            } else {
              return total + 1
            }
          }
          return total
        }, 0)
      }
      return 0
    },
    hasJjjjInFaciKey () {
      return this.faciKey.includes('jjjj')
    },
    cleanFaciKey () {
      return this.faciKey.replace(/jjjj/g, '')
    }
  },
  created () {
    if (this.faciData) {
      this.session = this.faciData.session
      this.adventureJudgements = this.faciData.adventureJudgements
      this.adventureTeams = this.faciData.adventureTeams
      this.ranking = this.faciData.ranking
      this.faciMessages = this.faciData.faciMessages
      this.galleryPhotos = this.faciData.galleryPhotos
    }
    // const dt = new Date()
    // dt.setSeconds(dt.getSeconds() + 5)
    // this.nextRefreshTime = dt
    // this.getRefreshSecondsText()
    // this.getRefreshSecondsTextInterval = setInterval(() => {
    //   this.getRefreshSecondsText()
    // }, 1000)
  },
  mounted () {
    if (!localStorage.getItem('faciUuid')) {
      localStorage.setItem('faciUuid', crypto.randomUUID())
    }

    if (
      !this.faciData || (
        this.faciData.facilitatorKey !== this.cleanFaciKey
      )
    ) {
      this.$store.commit('faciData', null)
    }

    if (window.matchMedia('(max-width: 767px)').matches) {
      this.useOldJudgementUi = true
    }

    this.getFaciData()
    // if (localStorage.useOldJudgementUi) {
    //   this.useOldJudgementUi = localStorage.useOldJudgementUi === 'true'
    // }
    if (localStorage.playToursAdminPlayDingOnJudgementChange) {
      this.playDingOnJudgementChange = localStorage.playToursAdminPlayDingOnJudgementChange === 'true'
    }
    if (localStorage.playToursAdminPlayDingOnMessageChange) {
      this.playDingOnMessageChange = localStorage.playToursAdminPlayDingOnMessageChange === 'true'
    }

    if (localStorage.getItem('playToursShownFaciTab')) {
      this.$store.commit('shownFaciTab', localStorage.getItem('playToursShownFaciTab'))
    }
    if (this.$route.query.page) {
      this.$store.commit('shownFaciTab', this.$route.query.page)
    }

    if (this.hasJjjjInFaciKey) {
      this.$store.commit('shownFaciTab', 'Judgements')
    }

    document.addEventListener('visibilitychange', () => {
      this.refreshCount = 0
    })
  },
  beforeDestroy () {
    clearInterval(this.getRefreshSecondsTextInterval)
  },
  watch: {
    session: {
      immediate: true,
      handler (session) {
        const faciSessionUpdaters = firebaseApp.firestore().collection('faciSessionUpdaters')
        if (session && session.id) {
          this.$bind('faciSessionUpdater', faciSessionUpdaters.doc(session.id))
        }
      }
    },
    faciSessionUpdater (updated, current) {
      if (
        updated && current &&
        updated.updateCount > current.updateCount
      ) {
        this.getFaciDataThrottled()
      }
    },
    refreshCount (updated, current) {
      if (updated > 120) {
        this.playDing()
        this.$router.push({ name: 'facilitatorActiveCheck' })
      }
    },
    useOldJudgementUi (updated, current) {
      localStorage.useOldJudgementUi = updated
    },
    playDingOnJudgementChange (updated, current) {
      localStorage.playToursAdminPlayDingOnJudgementChange = updated
    },
    playDingOnMessageChange (updated, current) {
      localStorage.playToursAdminPlayDingOnMessageChange = updated
    },
    adventureJudgements (updated, current) {
      if (
        (
          (!current && updated) ||
          (
            current &&
            updated &&
            updated.filter(a => a.isPendingJudgement).length > current.filter(a => a.isPendingJudgement).length
          )
        ) &&
        this.playDingOnJudgementChange
      ) {
        this.playDing()
      }
    },
    faciMessages (updated, current) {
      if (
        (
          (!current && updated) ||
          (
            current &&
            updated &&
            updated.filter(a => !a.repliedByFaci).length > current.filter(a => !a.repliedByFaci).length
          )
        ) &&
        this.playDingOnMessageChange
      ) {
        this.playDing()
      }
    },
    faciData (updated, current) {
      if (
        (updated !== null && !current) ||
        JSON.stringify(updated) !== JSON.stringify(current)
      ) {
        this.session = updated.session
        this.adventureJudgements = updated.adventureJudgements
        this.adventureTeams = updated.adventureTeams
        this.ranking = updated.ranking
        this.faciMessages = updated.faciMessages
        this.galleryPhotos = updated.galleryPhotos
      }
      if (
        updated && current && (
          JSON.stringify(updated.adventureTeams) !== JSON.stringify(current.adventureTeams) ||
          JSON.stringify(updated.adventureJudgements) !== JSON.stringify(current.adventureJudgements)
        )
      ) {
        this.nextRefreshSeconds = 15
      } else {
        this.nextRefreshSeconds = 25
      }
      this.refresher += 1
    }
  }
}
</script>

<style>
.faci-columns {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
  width: 100%;
  justify-content: center;
}

.faci-column {
  width: 100%;
  margin-bottom: 20px;
  /* margin-right: 10px; */
  flex-grow: 1;
}
</style>
