<template>
  <div>
    <div v-if="isShown" class="is-flex is-justify-content-center is-align-items-center mb-2" style="gap: 15px;">
      <div :class="['step', showTextField && !loading && !changeLog ? 'active' : 'inactive']">
        <span class="step-number">Step&nbsp;1.</span> Write instructions
      </div>
      <div :class="['step', loading ? 'active' : 'inactive']">
        <span class="step-number">Step&nbsp;2.</span> Queue for Assistant's response
      </div>
      <div :class="['step', !loading && (changeLog || newJson) ? 'active' : 'inactive']">
        <span class="step-number">Step&nbsp;3.</span> Confirm and implement changes
      </div>
    </div>
    <div class="is-flex is-justify-content-center" style="width: 100%;">
      <button v-if="!isShown" @click="activateTextField" class="button is-primary is-outlined is-rounded is-clickable has-text-weight-bold">
        <i class="fas fa-robot mr-2 mb-1"></i> Open PlayTours Assistant
      </button>
      <div class="field" v-else-if="showTextField" style="width: 100%;">
        <div class="control" style="width: 100%;">
          <textarea
            ref="instructionTextarea"
            v-model="instruction"
            class="textarea colourful-rounded-border"
            style="width: 100%;"
            :placeholder="instructionsPlaceholder"
            rows="4"
            :disabled="loading"
          ></textarea>
        </div>
        <div class="is-flex is-justify-content-flex-end mt-2">
          <button
            @click="cancelInput"
            class="button is-primary is-outlined is-small is-rounded mr-1"
          >
            <i class="fas fa-times mr-2"></i>
            Close
          </button>
          <button
            @click="getResponse"
            class="button is-primary is-small is-rounded"
            :disabled="loading || !instruction.trim()"
          >
            <i v-if="loading" class="fas fa-spinner fa-spin mr-2"></i>
            <i v-else class="fas fa-paper-plane mr-2"></i>
            {{ loading ? (countdown > 0 ? `Queueing for response, please wait for ${countdown} seconds...` : 'Taking longer than expected... please wait.') : 'Submit instructions' }}
          </button>
        </div>
      </div>
      <div v-else-if="changeLog" class="field" style="width: 100%;">
        <div class="control" style="width: 100%;">
          <p v-markdown class="textarea colourful-rounded-border" style="width: 100%; max-height: 200px; overflow-y: auto;">{{ changeLog }}</p>
        </div>
        <div class="is-flex is-justify-content-space-between mt-2">
          <div class="has-text-primary is-size-7" style="border: 1px solid #3e2d6d; border-radius: 5px; padding: 5px;">
          After implementing changes, always check and refine before saving.
          </div>
          <div class="is-flex">
            <button
              @click="cancelInput"
              class="button is-primary is-outlined is-small is-rounded mr-1"
            >
              <i class="fas fa-times mr-2"></i>
              Close
            </button>
            <button @click="editInstructions" class="button is-primary is-small is-rounded is-outlined mr-1">
              <i class="fas fa-edit mr-2"></i>
              Edit Instructions
            </button>
            <button v-if="isUndoable" @click="undoChanges" class="button is-warning is-small is-rounded mr-1">
              <i class="fas fa-undo mr-2"></i>
              Undo Changes
            </button>
            <button v-if="!isUndoable" @click="implementChanges" class="button is-primary is-small is-rounded">
              <i class="fas fa-check mr-2"></i>
              Implement Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import { jsonrepair } from 'jsonrepair'

export default {
  name: 'PlayToursAI',
  props: {
    rawAdventureJson: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isShown: false,
      instructionsPlaceholder: 'Type your instructions here. Specify specific chapters or tasks by number or name if necessary.\nTell the Assistant to think of new tasks, paste your ideas here and let the Assistant import it onto the game builder, change the tone and fix grammar, etc.\nFor now, the Assistant cannot help you add images, or manage UI Modifications.\nThis feature is in beta.',
      loading: false,
      showTextField: false,
      instruction: '',
      changeLog: null,
      newJson: null,
      originalJson: null,
      countdown: 60,
      isUndoable: false
    }
  },
  methods: {
    activateTextField () {
      if (this.$posthog) this.$posthog.capture('opened_assistant')
      this.isShown = true
      if (!this.instruction) this.showTextField = true
      this.$nextTick(() => {
        if (this.$refs.instructionTextarea) {
          this.$refs.instructionTextarea.focus()
        }
      })
    },
    getResponse () {
      if (!this.instruction) {
        alert('Please enter an instruction.')
        return
      }
      this.loading = true
      this.isUndoable = false
      this.startCountdown()
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('getPlayToursAIResponse', { timeout: 300000 }) // Set timeout to 5 minutes
      masterFunctionAdmin({
        rawAdventureJson: this.rawAdventureJson,
        instruction: this.instruction
      }).then(result => {
        this.originalJson = this.rawAdventureJson
        const answer = result.data.answer
        const changeLogMatch = answer.match(/\[plainEnglish\]([\s\S]*?)\[\/plainEnglish\]/)
        this.changeLog = changeLogMatch ? changeLogMatch[1].trim() : 'No changes found'
        this.instruction = this.instruction.trim() + '\n\n' + this.changeLog
        let jsonMatch = answer.match(/\[JSON\]([\s\S]*?)\[\/JSON\]/)
        if (!jsonMatch) {
          jsonMatch = answer.match(/\[JSON\]([\s\S]*)/)
        }
        const processJsonString = (jsonString) => {
          if (!jsonString.trim().startsWith('{') || !jsonString.trim().endsWith('}')) {
            const firstBraceIndex = jsonString.indexOf('{')
            const lastBraceIndex = jsonString.lastIndexOf('}')
            if (firstBraceIndex !== -1 && lastBraceIndex !== -1) {
              jsonString = jsonString.substring(firstBraceIndex, lastBraceIndex + 1)
            }
          }
          return jsonrepair(jsonString)
        }

        const handleJsonMatch = (jsonMatch) => {
          if (jsonMatch && jsonMatch[1]) {
            try {
              this.newJson = processJsonString(jsonMatch[1])
              this.replaceImagePlaceholders().then(() => {
                this.triggerUnsplashDownload()
              })
            } catch (err) {
              alert('Please try again.')
            }
          }
        }

        const handleAddChallengeMatch = (addChallengeMatch) => {
          if (addChallengeMatch && addChallengeMatch[1]) {
            try {
              this.newJson = JSON.parse(this.rawAdventureJson)
              let newChallenges = JSON.parse(processJsonString(addChallengeMatch[1]))
              if (!Array.isArray(newChallenges)) {
                newChallenges = [newChallenges]
              }
              newChallenges.sort((a, b) => {
                if (a.challengeIndex === undefined && b.challengeIndex !== undefined) {
                  return -1
                } else if (a.challengeIndex !== undefined && b.challengeIndex === undefined) {
                  return 1
                } else {
                  return 0
                }
              })
              newChallenges.forEach(challenge => {
                if (challenge.challengeIndex === undefined || challenge.challengeIndex === null) {
                  this.newJson.stages[challenge.stageIndex].challenges.push(JSON.parse(challenge.newChallengeJson))
                } else {
                  this.newJson.stages[challenge.stageIndex].challenges[challenge.challengeIndex] = JSON.parse(challenge.newChallengeJson)
                }
              })
              this.newJson = JSON.stringify(this.newJson)
              this.replaceImagePlaceholders().then(() => {
                this.triggerUnsplashDownload()
              })
            } catch (err) {
              console.log('err', err)
              alert('Please try again.')
            }
          }
        }

        handleJsonMatch(answer.match(/\[JSON\]([\s\S]*?)\[\/JSON\]/) || answer.match(/\[JSON\]([\s\S]*)/))
        handleAddChallengeMatch(answer.match(/\[ADDCHALLENGE\]([\s\S]*?)\[\/ADDCHALLENGE\]/))
      }).catch(err => {
        console.log('err', err)
        if (err.code === 'deadline-exceeded') {
          alert('The request took too long to complete. Please try again later.')
        } else {
          this.$buefy.toast.open({
            message: 'PlayTours Assistant faced an issue. Please try again in a few minutes.',
            type: 'is-danger',
            queue: false
          })
          this.resetState()
        }
      }).finally(() => {
        this.loading = false
        this.showTextField = false
        this.countdown = 60
      })
    },
    startCountdown () {
      this.countdown = 60
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(interval)
        }
      }, 1000)
    },
    implementChanges () {
      if (this.newJson) {
        this.$emit('updateRawAdventureJson', this.newJson)
        this.$buefy.toast.open({
          message: 'Changes implemented!',
          type: 'is-primary',
          queue: false
        })
        this.isUndoable = true
      }
      // this.resetState()
    },
    undoChanges () {
      if (this.originalJson) {
        this.$emit('updateRawAdventureJson', this.originalJson)
        this.$buefy.toast.open({
          message: 'Changes undone!',
          type: 'is-primary',
          queue: false
        })
        this.isUndoable = false
      }
    },
    editInstructions () {
      this.showTextField = true
      this.changeLog = null
      this.newJson = null
    },
    cancelInput () {
      if (this.isUndoable) {
        this.resetState()
      }
      this.isShown = false
    },
    resetState () {
      this.showTextField = false
      this.instruction = ''
      this.changeLog = null
      this.newJson = null
    },
    replaceImagePlaceholders () {
      return new Promise((resolve, reject) => {
        const regex = /IMGG\[(.*?)\]IMGG/g
        const matches = [...this.newJson.matchAll(regex)]
        if (matches.length > 0) {
          const promises = matches.map(match => {
            const keyword = match[1]
            return this.searchImage(keyword).then(url => {
              this.newJson = this.newJson.replace(match[0], `![${keyword}](${url})`)
            })
          })
          Promise.all(promises).then(() => resolve()).catch(err => reject(err))
        } else {
          resolve()
        }
      })
    },
    searchImage (keyword) {
      return new Promise((resolve, reject) => {
        const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
        masterFunctionAdmin({
          methodName: 'image-search',
          query: keyword
        }).then(result => {
          const files = result.data.results
          if (files.length > 0) {
            resolve(files[0].previewLink)
            this.triggerUnsplashDownload(files[0])
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('No images found')
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    triggerUnsplashDownload (file) {
      if (file.platform !== 'Unsplash') return true
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'unsplash-trigger-download',
        unsplashDownloadLink: file.unsplashDownloadLink
      })
    }
  }
}
</script>

<style scoped>
@keyframes rainbowBorder {
  0% { border-color: red; }
  12.5% { border-color: orange; }
  25% { border-color: indigo; }
  37.5% { border-color: green; }
  50% { border-color: blue; }
  62.5% { border-color: indigo; }
  75% { border-color: violet; }
  87.5% { border-color: pink; }
  100% { border-color: red; }
}

.colourful-rounded-border {
  border: 2px solid red; /* Initial color */
  border-radius: 12px;
  animation: rainbowBorder 6s linear infinite;
}

.step {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: grey;
}

.step.active {
  color: #3e2d6d;
  font-weight: bold;
}

.step-number {
  margin-right: 3px;
  font-weight: bold;
}
</style>
