<template>
  <div class="pb-6">
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <app-version-warning />
    <G2Requester />
    <section v-if="!profile.emailVerified" class="section is-main-section pb-2">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Verify your email"
        icon="fa-certificate"
      >
      <div class="px-5 py-5">
        <div class="container is-white has-text-grey mb-2">
          Please check your email <b>({{ profile.email }})</b> to verify your account.
          Unverified accounts will be deleted after 7 days.
          If you have used the wrong email, please logout and re-register.
        </div>
        <div class="container">
          <button
            @click="$store.dispatch('sendVerificationEmail')"
            class="button is-primary"
          >
          <i class="fas fa-envelope mr-2"></i>
            Resend verification email
          </button>
        </div>
      </div>
      </card-component>
    </section>
    <section v-if="organisation && !hideContactSolutionsTeam" class="section is-main-section pb-1">
      <contact-solutions-team />
    </section>
    <section v-if="organisation" class="section is-main-section pb-1">
      <div class="is-flex is-justify-content-flex-end">
        <create-game-button class="mb-2"/>
      </div>
      <adventures-table :perPage="5" />
    </section>
    <section v-if="organisation" class="section is-main-section pb-1">
      <tiles>
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="fa-running"
          :number="organisation.noOfConcurrentUsers"
          label="Active Devices"
          helpText="Devices are considered inactive 60 minutes after their last activity"
        />
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="fa-tachometer-alt"
          :number="organisation.maxConcurrentUsers"
          label="Max Concurrent Active Devices"
          helpText="Players will be prevented from creating teams or joining any game once active devices exceeds this"
        />
        <card-widget
          v-if="organisation.noOfUserCredits"
          class="tile is-child"
          type="is-info"
          icon="fa-coins"
          :number="organisation.noOfUserCredits"
          label="User Credits"
          helpText="This will only be used when active devices exceeds the max concurrent active device limit"
        />
      </tiles>
    </section>
    <section v-if="organisation" class="section is-main-section pb-1">
      <new-user-guide />
    </section>
    <section v-if="organisation" class="section is-main-section pb-1">
      <articles />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import * as chartConfig from '@/components/Charts/chart.config'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import ContactSolutionsTeam from '@/components/ContactSolutionsTeam'
import Articles from '@/components/Articles'
import NewUserGuide from '@/components/NewUserGuide'
import AppVersionWarning from '../components/AppVersionWarning.vue'
import CardComponent from '@/components/CardComponent'
import AdventuresTable from '@/components/AdventuresTable'
import CreateGameButton from '../components/CreateGameButton.vue'
import G2Requester from '@/components/G2Requester'

export default {
  name: 'Home',
  components: {
    CardComponent,
    CardWidget,
    ContactSolutionsTeam,
    Articles,
    NewUserGuide,
    Tiles,
    HeroBar,
    AppVersionWarning,
    AdventuresTable,
    CreateGameButton,
    G2Requester
  },
  data () {
    return {
      defaultChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsMain
      },
      hideContactSolutionsTeam: false
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    organisation () {
      return this.$store.state.organisation
    }
  },
  mounted () {
    this.fillChartData()

    const scr = document.createElement('script')
    scr.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-10814770953/q7fBCIzF9okDEIme8aQo'})"
    document.body.appendChild(scr)

    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
    this.hideContactSolutionsTeam = !!localStorage.hideContactSolutionsTeam
  },
  methods: {
    randomChartData (n) {
      const data = []

      for (let i = 0; i < n; i++) {
        data.push(Math.round(Math.random() * 200))
      }

      return data
    },
    fillChartData () {
      this.defaultChart.chartData = {
        datasets: [
          {
            fill: false,
            borderColor: chartConfig.chartColors.default.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.randomChartData(9)
          },
          {
            fill: false,
            borderColor: chartConfig.chartColors.default.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.randomChartData(9)
          },
          {
            fill: false,
            borderColor: chartConfig.chartColors.default.danger,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.danger,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.danger,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.randomChartData(9)
          }
        ],
        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09']
      }
    }
  }
}
</script>
