<template>
  <div>
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="createdAt"
      :data="tableData"
    >
      <b-table-column label="Team" field="teamName" sortable v-slot="props">
        {{ props.row.teamName }}
      </b-table-column>
      <b-table-column label="Challenge" field="challengeName" sortable v-slot="props">
        {{ props.row.challengeDetails.name }}
      </b-table-column>
      <b-table-column label="Points Awarded" field="points" sortable v-slot="props">
        {{ props.row.pointsAwarded ? props.row.pointsAwarded : 'N/A' }}
      </b-table-column>
      <b-table-column label="Approved?" field="isApproved" sortable v-slot="props">
        {{ props.row.isApproved !== null ? props.row.isApproved : 'Pending' }}
      </b-table-column>
      <b-table-column label="Created At" field="createdAt" sortable v-slot="props">
        {{ new Date(props.row.createdAt).toLocaleString('en-SG') }}
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right" style="flex-wrap: unset;">
          <button
            v-if="props.row.isPendingJudgement"
            @click="handleOpenJudgementModel(props.row.judgementId)"
            class="button is-primary"
          >
            <i class="fas fa-gavel"></i>
          </button>
          <i
            v-if="faciActivity && faciActivity.activities.some(x => x.judgementId === props.row.judgementId && ((new Date().getTime()) - (x.createdAt.nanoseconds / 1000000 + x.createdAt.seconds * 1000) < 90000))"
            class="fas has-text-grey-light fa-eye mb-2 ml-1">
          </i>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
    <div v-if="selectedJudgementId" class="modal" :class="{'is-active': selectedJudgementId}">
      <div @click="handleCloseJudgementModal" class="modal-background is-clickable"></div>
      <div
        class="modal-content"
        style="width: 100%; max-width: 800px; height: 80vh; max-height: 80vh;"
      >
        <div
          class="modal-card my-0 mx-2 p-0"
          style="width: 95%; height: 80vh; max-height: 80vh;"
        >
          <section class="modal-card-body" style="border-radius: 0.80rem;">
            <judgement
              :faciKeyProp="faciKey"
              :judgementIdProp="selectedJudgementId"
              v-on:close="handleCloseJudgementModal"
            >
            </judgement>
          </section>
        </div>
      </div>
      <button @click="handleCloseJudgementModal" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import Judgement from '../views/Judgement.vue'

export default {
  name: 'JudgementsTableOld',
  components: {
    Judgement
  },
  props: {
    tableData: {
      type: Array,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    },
    sessionId: {
      type: String
    },
    faciKey: {
      type: String
    }
  },
  data () {
    return {
      selectedJudgementId: null,
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: [],
      faciActivity: null
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  methods: {
    handleOpenJudgementModel (judgementId) {
      if (
        this.faciActivity &&
        this.faciActivity.activities.some(x => {
          return (
            x.judgementId === judgementId &&
            x.faciUuid !== localStorage.getItem('faciUuid') &&
            (new Date().getTime()) - (x.createdAt.nanoseconds / 1000000 + x.createdAt.seconds * 1000) < 90000
          )
        })
      ) {
        const confirmed = confirm('Another faci is currently viewing this. Are you sure you want to view this too?')
        if (!confirmed) return
      }
      this.selectedJudgementId = judgementId
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'register-faci-judgement-activity',
        judgementId,
        faciUuid: localStorage.getItem('faciUuid'),
        isEnter: true
      })
    },
    handleCloseJudgementModal () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'register-faci-judgement-activity',
        judgementId: this.selectedJudgementId,
        faciUuid: localStorage.getItem('faciUuid'),
        isEnter: false
      })
      this.selectedJudgementId = null
      this.$emit('refresh')
    }
  },
  mounted () {
    this.$bind(
      'faciActivity',
      firebaseApp.firestore()
        .collection('faciActivities')
        .doc(this.sessionId)
    )
  }
}
</script>
