<template>
  <div class="support-message is-flex is-flex-direction-column is-align-items-flex-end mt-2">
    <div class="has-text-grey-light is-clickable" @click="copyEmail">
      "Worried? Anxious? Frustrated? We're always here to help. Drop us an email at hello@playtours.app"
    </div>
    <div class="has-text-grey-light">
    Mo, Founder of PlayTours
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreatePlayToursSupportMessage',
  methods: {
    copyEmail () {
      const email = 'hello@playtours.app'
      navigator.clipboard.writeText(email).then(() => {
        this.$buefy.toast.open({
          message: 'Email copied to clipboard!',
          type: 'is-primary',
          queue: false
        })
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Failed to copy email.',
          type: 'is-danger',
          queue: false
        })
      })
    }
  }
}
</script>

<style scoped>
/* Add any specific styles for the SupportMessage component here */
</style>
