export const getNypRoutes = () => {
  return [
    {
      meta: {
        title: 'Avatars Screen'
      },
      path: '/nyp/avatars',
      name: 'nypAvatarsScreen',
      component: () => import('./AvatarsScreen.vue')
    },
    {
      meta: {
        title: 'Avatars Screen'
      },
      path: '/nyp/avatars2',
      name: 'nypAvatarsScreen2',
      component: () => import('./AvatarsScreen2.vue')
    }
  ]
}
