<template>
  <div>
    <hero-bar>
      Game Builder
      <router-link :to="{name:'playtoursAssistant'}" slot="right" class="button is-primary is-light mr-1">
        <i class="fas fa-hands-helping mr-2"></i>
        Need help to start?
      </router-link>
      <div @click="$refs.createPlayTourForm.reset(true)" slot="right" class="button is-primary is-light">
        <i class="fas fa-history mr-2"></i>
        Reset
      </div>
    </hero-bar>
    <app-version-warning class="remove-max-width mb-0" />
    <small-screen-notice />
    <section v-if="showIncognitoWarning" class="section is-main-section remove-max-width pb-2 pt-5">
      <article class="message is-warning">
        <div class="message-header">
          <p>Make sure you are not using incognito mode</p>
          <button @click="closeIncognitoWarning()" class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
          As changes are saved locally on your browser, using an incognito browser will erase your progress if you close it. <strong>Do not use the incognito mode when building games.</strong>
        </div>
      </article>
    </section>
    <section class="section is-main-section remove-max-width pt-3">
      <tiles>
        <create-play-tour-form class="tile is-child" ref="createPlayTourForm" />
      </tiles>
    </section>
  </div>
</template>

<script>
import CreatePlayTourForm from '@/components/CreatePlayTourForm'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import AppVersionWarning from '../components/AppVersionWarning.vue'
import SmallScreenNotice from '../components/SmallScreenNotice.vue'

export default {
  name: 'PlayTourCreate',
  components: {
    HeroBar,
    // ClientsTableSample,
    CreatePlayTourForm,
    Tiles,
    AppVersionWarning,
    SmallScreenNotice
  },
  data () {
    return {
      showIncognitoWarning: true
    }
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })

    this.showIncognitoWarning = localStorage.playToursDoNotShowIncognitoWarning !== 'true'
  },
  methods: {
    closeIncognitoWarning () {
      localStorage.playToursDoNotShowIncognitoWarning = 'true'
      this.showIncognitoWarning = false
    }
  }
}
</script>
