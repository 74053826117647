<template>
  <div>
    <div id="chapter-tutorial-link" class="is-size-7 has-text-grey has-text-weight-bold is-clickable" @click="openTutorial">
      <i class="fas fa-external-link-alt mr-1"></i>
      Should I add more than 1 chapter?
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChapterTutorialModal',
  methods: {
    openTutorial () {
      window.open('https://www.playtours.app/post/using-playtours-chapter-task-point-system', '_blank')
    }
  }
}
</script>
