import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import store from '../store'

import { trackUsage } from '../utils/trackUsage'
import { getNypRoutes } from '../nyp/generateNypRoutes'
import posthog from 'posthog-js'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Login',
      requiresAuth: false
    },
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '../views/Login.vue')
  },
  {
    meta: {
      title: 'Price Checker',
      requiresAuth: false
    },
    path: '/price-checker',
    name: 'PriceChecker',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '../views/PriceChecker.vue')
  },
  {
    meta: {
      title: 'PlayTours',
      requiresAuth: true
    },
    path: '/playtours',
    name: 'playtours',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PlayTours.vue')
  },
  {
    meta: {
      title: 'Manage Game',
      requiresAuth: true
    },
    path: '/playtours/manage/:adventureId',
    name: 'managePlayTour',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PlayTourManage.vue')
  },
  {
    meta: {
      title: 'Game Assistant',
      requiresAuth: true
    },
    path: '/playtours/assistant',
    name: 'playtoursAssistant',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PlayToursAssistant.vue')
  },
  {
    meta: {
      title: 'Create Game',
      requiresAuth: true
    },
    path: '/playtours/create',
    name: 'createPlayTour',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PlayTourCreate.vue')
  },
  {
    meta: {
      title: 'Game Builder',
      requiresAuth: true
    },
    path: '/playtours/edit/:adventureId',
    name: 'editPlayTour',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PlayTourEdit.vue')
  },
  {
    meta: {
      title: 'Upload Game CSV',
      requiresAuth: true
    },
    path: '/playtours/csv-upload',
    name: 'uploadCsvPlayTour',
    component: () => import(/* webpackChunkName: "forms" */ '../views/PlayTourCsvUpload.vue')
  },
  {
    meta: {
      title: 'Add Session',
      requiresAuth: true
    },
    path: '/playtours/session/add/:adventureId',
    name: 'addSession',
    component: () => import(/* webpackChunkName: "forms" */ '../views/SessionAdd.vue')
  },
  {
    meta: {
      title: 'Edit Session',
      requiresAuth: true
    },
    path: '/playtours/session/edit/:sessionId',
    name: 'editSession',
    component: () => import(/* webpackChunkName: "forms" */ '../views/SessionEdit.vue')
  },
  {
    meta: {
      title: 'Admins',
      requiresAuth: true
    },
    path: '/admins/view',
    name: 'admins',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Admins.vue')
  },
  {
    meta: {
      title: 'Add Admin',
      requiresAuth: true
    },
    path: '/admins/add',
    name: 'addAdmins',
    component: () => import(/* webpackChunkName: "forms" */ '../views/AdminAdd.vue')
  },
  {
    meta: {
      title: 'Edit Admin',
      requiresAuth: true
    },
    path: '/admins/edit/:uid',
    name: 'editAdmin',
    component: () => import(/* webpackChunkName: "forms" */ '../views/AdminEdit.vue')
  },
  {
    meta: {
      title: 'Profile',
      requiresAuth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    meta: {
      title: 'Organisation',
      requiresAuth: true
    },
    path: '/organisation',
    name: 'organisation',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Organisation.vue')
  },
  {
    meta: {
      title: 'Add Files',
      requiresAuth: true
    },
    path: '/files/add',
    name: 'addImage',
    component: () => import(/* webpackChunkName: "profile" */ '../views/FilesAdd.vue')
  },
  {
    meta: {
      title: 'View Files',
      requiresAuth: true
    },
    path: '/files/view',
    name: 'viewFiles',
    component: () => import(/* webpackChunkName: "profile" */ '../views/FilesView.vue')
  },
  {
    meta: {
      title: 'Facilitator'
    },
    path: '/faci/:faciKey',
    name: 'facilitator',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Facilitator.vue')
  },
  {
    meta: {
      title: 'Facilitator Activity Check'
    },
    path: '/faci-active-check/',
    name: 'facilitatorActiveCheck',
    component: () => import(/* webpackChunkName: "profile" */ '../views/FacilitatorActiveCheck.vue')
  },
  {
    meta: {
      title: 'Judgement'
    },
    path: '/faci/:faciKey/judgement/:judgementId',
    name: 'submitJudgement',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Judgement.vue')
  },
  {
    meta: {
      title: 'Subscription',
      requiresAuth: true
    },
    path: '/subscription',
    name: 'subscription',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Subscription.vue')
  },
  {
    meta: {
      title: 'Privacy Policy',
      requiresAuth: true
    },
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "profile" */ '../views/PrivacyPolicy.vue')
  },
  {
    meta: {
      title: 'Certificate Validator'
    },
    path: '/cert-validator/:certValidatorKey',
    name: 'certificateValidator',
    component: () => import(/* webpackChunkName: "profile" */ '../views/CertificateValidator.vue')
  },
  {
    meta: {
      title: 'Super Admin'
    },
    path: '/super-admin',
    name: 'superAdmin',
    component: () => import(/* webpackChunkName: "profile" */ '../views/SuperAdmin.vue')
  },
  {
    meta: {
      title: 'Exhibit'
    },
    path: '/exhibit/:sessionId',
    name: 'exhibit',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Exhibit.vue')
  },
  {
    meta: {
      title: 'QR Code'
    },
    path: '/qr/:qrValue',
    name: 'timeSensitiveQrCode',
    component: () => import('../views/QrBarcodeTimeSensitive.vue')
  },
  ...getNypRoutes()
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  posthog.capture('$pageleave')
  if (
    from.fullPath.includes('/playtours/edit') &&
    !store.state.savedPlayTours
  ) {
    let confirmed = confirm('Unsaved changes will be lost. "OK" to leave this page. "Cancel" to stay.')
    if (!confirmed) return
    confirmed = confirm('Click "OK" once again to leave this page and lose all unsaved changes.')
    if (!confirmed) return
  }

  if (
    from.fullPath.includes('/playtours/create') &&
    to.fullPath.includes('/playtours/assistant')
  ) {
    localStorage.playtoursSkipGameAssistant = 'false'
  }
  if (
    to.fullPath.includes('/playtours/assistant') &&
    localStorage.playtoursSkipGameAssistant === 'true'
  ) {
    next({ name: 'createPlayTour' })
  }

  if (to.fullPath.includes('/playtours')) trackUsage()

  const fromOutsidePages = from.fullPath.includes('/login') || from.fullPath.includes('/price-checker')
  const toOutsidePages = to.fullPath.includes('/login') || to.fullPath.includes('/price-checker')
  if (!fromOutsidePages && toOutsidePages) {
    document.querySelector('html').className = ''
  } else if (fromOutsidePages && !toOutsidePages) {
    document.querySelector('html').className = 'has-aside-left has-aside-mobile-transition has-navbar-fixed-top has-aside-expanded'
  }

  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    const user = firebase.auth().currentUser
    if (user) {
      next()
    } else {
      document.querySelector('html').className = ''
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  Vue.nextTick(() => {
    posthog.capture('$pageview')
  })
})

export default router
