/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
// import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Firebase */
// eslint-disable-next-line no-unused-vars
import firebaseApp from '@/firebase/init'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

/* Directives */
import markdown from './directives/markdown'
import onlyInt from './directives/onlyInt'
import onlyNum from './directives/onlyNum'

import { firestorePlugin } from 'vuefire'
import VueGtag from 'vue-gtag'
import VuePannellum from 'vue-pannellum'
import VueViewer from 'v-viewer'
import VueMixpanel from 'vue-mixpanel'
import VueConfetti from 'vue-confetti'
import PosthogPlugin from './plugins/posthog'

import json from '../package.json'
store.commit('appVersion', json.version)

Vue.use(onlyInt)
Vue.use(onlyNum)
Vue.directive('markdown', markdown)
Vue.use(VueConfetti)
Vue.config.productionTip = false
if (
  window.location.hostname === 'admin.playtours.app' &&
  localStorage.getItem('playToursAdminEmail') &&
  !localStorage.getItem('playToursAdminEmail').includes('playtours.app')
) {
  Vue.use(PosthogPlugin)
}

Vue.use(VueGtag, {
  config: { id: 'G-L7NBE5WKNH' },
  appName: 'PlayTours Admin',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.component('VPannellum', VuePannellum)
Vue.use(VueViewer)

Vue.use(VueMixpanel, {
  token: '4aa502a96b1e25d91f64ac2a38bb2dc8',
  config: {
    debug: Vue.config.devtools
  }
})

store.commit('faciData', localStorage.playToursAdminFaciData ? JSON.parse(localStorage.playToursAdminFaciData) : null)

/* Default title tag */
const defaultDocumentTitle = 'PlayTours Admin'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(firestorePlugin)

Vue.use(Buefy)

let app = null
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    store.commit('user', { user })
  } else {
    store.commit('removeUser')
  }
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
